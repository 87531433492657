/**
 * External Dependencies
 */
import barba from '@barba/core'
import gsap from 'gsap'
import once from './routes/once'
import common from './routes/common'
import contact from './routes/contact'
import home from './routes/home'
import products from './routes/products'
import map from './routes/map'


barba.init({
  debug: true,
  timeout: 2000,
  prevent: ({ el }) => {
    if(el.classList && el.classList.contains('chocolat-image') && el.pathname.includes('.jpg')){
      return true
    }
    if(el.href.includes('locations')){
      return true
    }
    if(el.classList && el.classList.contains('no-app')){
      return true
    }
    return false
  },
  transitions: [
    {
      leave(data) {
          const done = this.async()
          gsap.to(data.current.container, 0.35, {
              opacity: 0,
              y: '-30px',
              ease: 'power2.out',
              onComplete: done,
          })

      },
      enter(data) {
          const done = this.async()
          const $el = data.next.container

          data.current.container.style.display = 'none'
          window.scroll(0,0)
          gsap.set($el, { opacity: 0, y: '100px', visibility: 'visible' })
          gsap.to($el,
            0.35,
            {
              opacity: 1,
              y: 0,
              ease: 'power2.out',
              onComplete: done,
          })
      },
    },
  ],
  views: [
    {
      namespace: 'home',
      afterEnter() {
        home.init()
      }
    },
    {
      namespace: 'products',
      afterEnter() {
        setTimeout(() => {
          products.init()
        }, 200)

      }
    },
    {
      namespace: 'map',
      afterEnter() {
        map.init()
      }
    },
    {
      namespace: 'contact',
      afterEnter() {
        contact.init()
      }
    },
  ]
});

once.init()

barba.hooks.afterEnter((data) => {
  common.init()
});

import Swiper, { Autoplay, Navigation, Fade } from 'swiper';
import Splitting from "splitting";
import { gsap } from 'gsap';

Swiper.use([Autoplay, Navigation]);

export default {
    sliderCollections() {

        const slider = document.querySelector('.slider-collections')
        if(slider){
            const swiper = new Swiper(slider, {
                loop: true,
                speed: 600,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            })
        }
    },
    heroHome() {

        Splitting()
        const gallery = document.querySelector('.hero-swiper')
        if(gallery){

            const timelines = []
            const myLinks = document.querySelectorAll(".link-slide")
            const titlesSlides = document.querySelectorAll(".hero-titles__elem")
            if(titlesSlides){
            titlesSlides.forEach((item, i) => {
                timelines[i] = gsap.timeline({paused: true})
                .set(item.querySelectorAll('.btn-cont__inner'), {
                    y: '120%',
                })
                .set(item.querySelectorAll('.hero-titles__upper'), {
                    opacity: 0,
                })
                .set(item.querySelectorAll('.hero-titles__item__line .char'), {
                    y: '120%',
                })
                .add( () => {
                    item.classList.toggle('hero-titles__elem--current');
                })
                .to( item.querySelectorAll('.hero-titles__item__line .char'), 0.5, {
                    ease: 'power3.out',
                    y: '0%',
                    stagger: {
                        each: 0.010,
                    }
                })
                .to( item.querySelectorAll('.hero-titles__upper'), 0.4, {
                    ease: 'power3.out',
                    opacity: 1,
                },0.3)
                .to( item.querySelectorAll('.btn-cont__inner'), 0.4, {
                    ease: 'power3.out',
                    y: '0%',
                },0.5)
            })


            }

    
    
            const slidernavigateto = index => {
                myLinks.forEach((item, i) => {
                    item.classList.remove('active')
                })
                myLinks[index].classList.add('active')
    
            }

            const swiper2 = new Swiper(gallery, {
                loop: true,
                spaceBetween: 0,
                speed: 2000,
                autoplay: {
                    delay: 10000,
                },
                on: {
                    init: swiper2 => {
                        timelines[0].play()
                        slidernavigateto(swiper2.realIndex)
                    },
                    transitionStart: swiper2 => {
                        if(swiper2.slides[swiper2.previousIndex -1]){
                            timelines[swiper2.previousIndex -1].reverse()
                        }

                    },
                    transitionEnd: swiper2 => {
                        if(swiper2.slides[swiper2.realIndex]){
                            timelines[swiper2.realIndex].play()
                        }
                    },
                    slideChange: swiper2 => {
                        slidernavigateto(swiper2.realIndex)
                    },

                },
            })



            myLinks.forEach((item, i) => {
                item.addEventListener("click", event => {
                    event.preventDefault()
                    swiper2.slideTo(i+1, 1000)
                })
            })

        }

    },
    init() {
        this.heroHome()
        this.sliderCollections()
    }
}

/* global globalapp */

import Swiper, { Thumbs } from 'swiper';
import once from './once';
Swiper.use([Thumbs]);

export default {
    favouritesAdd() {
        const btns = document.querySelectorAll('.btn-favourites')
        const favouritescart = document.querySelector('.cart-favourites')
        const favouritescount = document.querySelector('.quantity-favourites')
        const favouritestrigger = document.querySelector('.favourites-trigger')

        btns.forEach(btn => {
            btn.addEventListener('click', function(e){
                e.preventDefault()

                const skuElem = document.querySelector('span[data-skucont]')
                let selected = []
                const required = document.querySelectorAll('.input-required')
                required.forEach(item => {
                    if(item.checked){
                        selected.push(parseInt(item.name))
                    }
                });


                // console.log(selected)



                const request = new XMLHttpRequest();

                request.open('POST', globalapp.ajax, true);
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
                request.onload = function () {
                    if (this.status >= 200 && this.status < 400) {
                        const response = JSON.parse(this.response)
                        if(response.success){

                            if(response.count === 0){
                                favouritestrigger.classList.add('not-shown')
                            } else {
                                favouritestrigger.classList.remove('not-shown')
                            }

                            favouritescart.innerHTML = response.data
                            favouritescount.innerHTML = response.count
                            favouritescart.classList.add('shown')
                            // btn.classList.add('hidden')
                            once.favouritesRemove()
                        }
                    } else {
                        // Response error
                    }
                };
                request.onerror = function() {
                    // Connection error
                };
                if(selected.length){
                    request.send('action=addToCart&id=' + parseInt(btn.dataset.id) + '&sku='  + skuElem.textContent + '&selected=' + selected.join(','));
                } else {
                    request.send('action=addToCart&id=' + parseInt(btn.dataset.id)  + '&sku=' + skuElem.textContent);
                }

            })
        })

    },
    galleryImages() {
        const gallery = document.querySelector('.main-gallery')
        const thumbs = document.querySelector('.thumbs-gallery')
        const contentcolumn = document.querySelector('.content-column')
        if(gallery && contentcolumn){
            if(gallery.offsetHeight < contentcolumn.offsetHeight && window.screen.height >= 1024){
                gallery.classList.add('stick')
            }
        }
        if(gallery && thumbs){


            const swiper = new Swiper(thumbs, {
                spaceBetween: 10,
                slidesPerView: 'auto',
                freeMode: true,
                watchSlidesProgress: true,
                slideToClickedSlide: true,
            })
            const swiper2 = new Swiper(gallery, {
                autoHeight: true,
                spaceBetween: 0,
                touchRatio: 0,
                thumbs: {
                    swiper: swiper,
                }
            })


            const swatches = document.querySelector('.swatches')
        
            if(swatches){
                const priceElem = document.querySelector('span[data-price]')
                const skuElem = document.querySelector('span[data-skucont]')
                const swatchesArray = swatches.querySelectorAll('.swatch-item')
                const allCheckboxes = document.querySelectorAll('.input-required')
                const totalContainer = document.querySelector('.total-required-products')

                swiper2.on('slideChangeTransitionEnd', function(slider){
                    const elem = slider.slides[slider.activeIndex]
                    const swatchFind = swatches.querySelector('.swatch-item[data-image="'+ elem.dataset.image +'"]')
                    if(swatchFind){
                        swatchFind.click()
                    } else {
                        swatchesArray.forEach(item => {
                            item.classList.remove('active')
                        })

                        if(priceElem){
                            priceElem.innerHTML = priceElem.dataset.price
                        }

                        if(skuElem){
                            skuElem.innerHTML = skuElem.dataset.skucont
                        }
                    }
                })

                swatchesArray.forEach(item => {
                    item.addEventListener('click', event => {
                        event.preventDefault()
                        const hasclass = item.classList.contains('active')
                        if(allCheckboxes && totalContainer){
                            allCheckboxes.forEach((item, i) => {
                                item.checked = false;
                            })
                            totalContainer.style.display = 'none'
                        }

                        swatchesArray.forEach(all => {
                            all.classList.remove('active')
                        })

                        const image = item.dataset.image
                        const price = item.dataset.price
                        const sku = item.dataset.sku

                        if(!hasclass){
                            item.classList.add('active')


                            if(image && swiper2 && gallery){
                                const getAllSlides = [...gallery.querySelectorAll('.swiper-slide')]
                                const getSlide = gallery.querySelector('.swiper-slide[data-image="' + image + '"]')
                                if(getAllSlides && getSlide){
                                    swiper2.slideTo(getAllSlides.indexOf(getSlide), 500, false)
                                }
        
                            } 
                            else{
                                if(swiper2){
                                    swiper2.slideTo(0, 500, false)
                                }
                            }

                            if(price && priceElem){
                                priceElem.innerHTML = price
                            } else {
                                priceElem.innerHTML = priceElem.dataset.price
                            }
                            if(sku && skuElem){
                                skuElem.innerHTML = sku
                            } else {
                                skuElem.innerHTML = skuElem.dataset.skucont
                            }

                        } 
                        else {
                            if(priceElem){
                                priceElem.innerHTML = priceElem.dataset.price
                            }

                            if(skuElem){
                                skuElem.innerHTML = skuElem.dataset.skucont
                            }

                            if(swiper2){
                                swiper2.slideTo(0, 500, false)
                            }
                        }

                    })
                })
            }


        }




    },
    onRequiredProductsUpdate() {

        const allCheckboxes = document.querySelectorAll('.input-required')
        const mainPrice = document.querySelector('.main-price')
        const totalContainer = document.querySelector('.total-required-products')

        if(mainPrice && allCheckboxes && totalContainer){
            const dataTotal = totalContainer.querySelector('[data-totalrequired]')
            allCheckboxes.forEach((item, i) => {
                item.addEventListener('click', function(){
                    let total = parseFloat(mainPrice.querySelector('[data-price]').textContent)
                    allCheckboxes.forEach((item, i) => {
                        if (item.checked == true){
                            total += parseFloat(item.dataset.price)
                        }
                    })

                    if(total == parseFloat(mainPrice.querySelector('[data-price]').dataset.price)){

                        dataTotal.textContent = 0
                        totalContainer.style.display = 'none'
                    } else {
                        dataTotal.textContent = total
                        totalContainer.style.display = 'block'


                    }



                })

            })
        }

    },
    tabsLoad() {
        const myTabs = document.querySelectorAll(".nav-tabs > li a")

        myTabs.forEach((item, i) => {
            if(item === myTabs[0]){
                item.classList.add('active')
                const activePaneId = item.getAttribute("href");
                const activePane = document.querySelector(activePaneId);
                activePane.classList.add("active");
            }
            item.addEventListener("click", event => {
                event.preventDefault()
                myTabs.forEach((tab, i) => {
                    myTabs[i].classList.remove("active");
                })
                item.classList.add('active');
                const clickedTab = event.currentTarget;
                const myContentPanes = document.querySelectorAll(".tab-pane");
                myContentPanes.forEach((itemPane, i) => {
                    itemPane.classList.remove("active");
                })
                const activePaneId = item.getAttribute("href");
                const activePane = document.querySelector(activePaneId);
                activePane.classList.add("active");
            })
        })
        if(window.location.hash) {
            myTabs.forEach((item, i) => {
                if(item.getAttribute("href") === window.location.hash){
                    item.click()
                }
            })
        }
    },
    init() {
        this.tabsLoad()
        this.galleryImages()
        this.favouritesAdd()
        this.onRequiredProductsUpdate()
    }
}

/* global wpcf7 */
import { lazySelector } from './once'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
import init from '../cf7/init';
import submit from '../cf7/submit';
import reset from '../cf7/reset';
import validate from '../cf7/validate';

import AOS from 'aos'

gsap.registerPlugin(ScrollToPlugin);

export default {
    lazyLoadRefresh() {
        if (lazySelector) {
            lazySelector.update()
        }
    },
    accordionsLoad() {
        const accordions = document.querySelectorAll('.accordion')
        
        accordions.forEach((item, i) => {
            const accordionitem = item.querySelectorAll('.accordion-item')
            accordionitem.forEach( child => {
                const link = child.querySelector('.accordion-link')
                const content = child.querySelector('.accordion-content')
                link.addEventListener('click', event => {
                    event.preventDefault()
                    content.classList.toggle('active')
                    link.classList.toggle('active')
                })
            })
        })

    },
    animationsRefresh(){
        AOS.refresh()
    },
    contactForm(){

        if ( typeof wpcf7 === 'undefined' ) {
            console.error( "wpcf7 is not defined." );
            return;
        }

        if ( typeof wpcf7.api === 'undefined' ) {
            console.error( "wpcf7.api is not defined." );
            return;
        }

        if ( typeof window.fetch !== 'function' ) {
            console.error( "Your browser does not support window.fetch()." );
            return;
        }

        if ( typeof window.FormData !== 'function' ) {
            console.error( "Your browser does not support window.FormData()." );
            return;
        }

        const forms = document.querySelectorAll( '.wpcf7 > form' );

        if ( typeof forms.forEach !== 'function' ) {
            console.error( "Your browser does not support NodeList.forEach()." );
            return;
        }

        wpcf7 = {
            init,
            submit,
            reset,
            validate,
            ...( wpcf7 ?? {} ),
        };

        forms.forEach( form => wpcf7.init( form ) );

    },
    smoothscroll() {
        const links = document.body.querySelectorAll(".scroll-link")
        links.forEach(item => {
            item.addEventListener("click", event => {
                event.preventDefault()
                const activePaneId = item.getAttribute("href")
                if(document.querySelector(activePaneId)){
                    const offsetHeight = document.querySelector('.header-app').offsetHeight
                    gsap.to(window, {scrollTo: {y: activePaneId, offsetY: offsetHeight}, overwrite: true})
                } else {
                    item.classList.add('hidden')
                }
            })
        })
    },
    init() {
        this.lazyLoadRefresh()
        this.smoothscroll()
        this.contactForm()
        this.animationsRefresh()
        this.accordionsLoad()
    },

}

/* global mapstockists */

import mapboxgl from '!mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import * as turf from '@turf/turf'

export default {
	getListlocations(){
		return mapstockists
	},
	mapboxLoad(){
		const listingsresultscont = document.getElementById('listings-results')
		const app = this
		mapboxgl.accessToken = 'pk.eyJ1Ijoidml0YWxhZ2VuY3kiLCJhIjoiY2pzeXMxdHh2MHplcjQ0b2YwdjZqc2ljeSJ9.ocanScMc7nrOCDD8SLOIiA';

	    const map = new mapboxgl.Map({
	    	container: 'map-stockists',
			style: 'mapbox://styles/vitalagency/ckny9u02w3txe17oakj403x5h',
			center: [-7.1454367,54.3137209],
			zoom: 3,
			scrollZoom: false,
	    });
	    map.addControl(new mapboxgl.NavigationControl());
	    mapstockists.features.forEach((store, i) => {
	        store.properties.id = i;
	    });
      	map.on('load', () => {
	        map.addSource('places', {
	          'type': 'geojson',
	          'data': mapstockists
	        });
			const geocoder = new MapboxGeocoder({
			  	accessToken: mapboxgl.accessToken, // Set the access token
			  	mapboxgl: mapboxgl, // Set the mapbox-gl instance
				marker: {
					color: '#8B421E'
				},
			  	country: 'gb',
			});
	        // buildLocationList(mapstockists);
	        map.addControl(geocoder, 'top-left');
	        // addMarkers();
        	geocoder.on('result', (event) => {
        		// const newList = false;
          		const searchResult = event.result.geometry;
	          	const options = {
	          		units: 'miles',
	          	};
	          	const listDisplay = []
	          	const listNotDisplay = []
	          	for (const store of app.getListlocations().features) {
		            const distance = turf.distance(
		              searchResult,
		              store.geometry,
		              options
		            );
		            if(distance < 10){
		            	const newStore = store
		            	newStore.properties.distance = distance
		            	if(newStore.properties.HasaDisplay){
							listDisplay.push(newStore)
		            	} else {
		            		listNotDisplay.push(newStore)
		            	}
		            }
	          	}
				listDisplay.sort((a, b) => {
		            if (a.properties.distance > b.properties.distance) {
		              return 1;
		            }
		            if (a.properties.distance < b.properties.distance) {
		              return -1;
		            }
		            return 0;
	          	});

				listNotDisplay.sort((a, b) => {
		            if (a.properties.distance > b.properties.distance) {
		              return 1;
		            }
		            if (a.properties.distance < b.properties.distance) {
		              return -1;
		            }
		            return 0;
	          	});

	          	buildLocationList([...listDisplay, ...listNotDisplay]);
	          	clearMarkers()
	          	addMarkers([...listDisplay, ...listNotDisplay]);
	          	if(listDisplay.length || listNotDisplay.length){
	          		listingsresultscont.classList.remove('hidden')
		          	createPopUp([...listDisplay, ...listNotDisplay][0]);
		          	const activeListing = document.getElementById(
		            	`listing-${[...listDisplay, ...listNotDisplay][0].properties.id}`
		          	);
		          	activeListing.classList.add('active');
		          	const bbox = getBbox([...listDisplay, ...listNotDisplay], 0, searchResult);
		          	map.fitBounds(bbox, {
		            	padding: 100
		          	});
	          	} else{
	          		listingsresultscont.classList.add('hidden')
	          	}



        	})


      	})
      	function getBbox(sortedStores, storeIdentifier, searchResult) {
        	const lats = [
		          sortedStores[storeIdentifier].geometry.coordinates[1],
		          searchResult.coordinates[1]
	        ];
	        const lons = [
	          sortedStores[storeIdentifier].geometry.coordinates[0],
	          searchResult.coordinates[0]
	        ];
	        const sortedLons = lons.sort((a, b) => {
	          if (a > b) {
	            return 1;
	          }
	          if (a.distance < b.distance) {
	            return -1;
	          }
	          return 0;
	        });
	        const sortedLats = lats.sort((a, b) => {
	          if (a > b) {
	            return 1;
	          }
	          if (a.distance < b.distance) {
	            return -1;
	          }
	          return 0;
	        });
	        return [
	          [sortedLons[0], sortedLats[0]],
	          [sortedLons[1], sortedLats[1]]
	        ];
      	}
      	let markersElements = []
		function clearMarkers(){
		  markersElements.forEach((marker) => marker.remove());
		  markersElements = [];
		}
      	function addMarkers(newlistherre) {
	        for (const marker of newlistherre) {
	          const el = document.createElement('div');
	          el.id = `marker-${marker.properties.id}`;
	          el.className = 'marker';


	          const newmarker = new mapboxgl.Marker(el, { offset: [0, -23] })
	            .setLngLat(marker.geometry.coordinates)
	            .addTo(map);

	          markersElements.push(newmarker)
	          el.addEventListener('click', (e) => {
	            flyToStore(marker);
	            createPopUp(marker);
	            const activeItem = document.getElementsByClassName('active');
	            e.stopPropagation();
	            if (activeItem[0]) {
	              activeItem[0].classList.remove('active');
	            }
	            const listing = document.getElementById(
	              `listing-${marker.properties.id}`
	            );
	            listing.classList.add('active');
	          });
	        }
      	}


	    function buildLocationList(stores) {

          	const listings = document.getElementById('listings');
          	while (listings.firstChild) {
            	listings.removeChild(listings.firstChild);
          	}

	        for (const store of stores) {
	          /* Add a new listing section to the sidebar. */
	          const listings = document.getElementById('listings');
	          const listing = listings.appendChild(document.createElement('div'));
	          /* Assign a unique `id` to the listing. */
	          listing.id = `listing-${store.properties.id}`;
	          /* Assign the `item` class to each listing for styling. */
	          listing.className = 'item';

	          /* Add the link to the individual listing created above. */
	          if(store.properties.Name){
		          const link = listing.appendChild(document.createElement('a'));
		          link.href = '#';
		          link.className = 'title text-xs md:text-sm uppercase tracking-widest mb-2 font-semibold block';
		          link.id = `link-${store.properties.id}`;
		          link.innerHTML = `${store.properties.Name}`;

		          link.addEventListener('click', function () {
		            for (const feature of stores) {
		              if (this.id === `link-${feature.properties.id}`) {
		                flyToStore(feature);
		                createPopUp(feature);
		              }
		            }
		            const activeItem = document.getElementsByClassName('active');
		            if (activeItem[0]) {
		              activeItem[0].classList.remove('active');
		            }
		            this.parentNode.classList.add('active');
		          });


	          }


	          /* Add details to the individual listing. */
	          const details = listing.appendChild(document.createElement('div'));
	          details.className ='font-light text-sm'
	          // details.innerHTML = `${store.properties.City}`;

	          if (store.properties.AddressLine1) {
	            details.innerHTML += `${store.properties.AddressLine1}`;
	          }
	          if (store.properties.AddressLine2) {
	            details.innerHTML += `<br/ >${store.properties.AddressLine2}`;
	          }
	          if (store.properties.AddressLine3) {
	            details.innerHTML += `<br/ >${store.properties.AddressLine3}`;
	          }
	          if (store.properties.AddressLine4) {
	            details.innerHTML += `<br/ >${store.properties.AddressLine4}`;
	          }
	          if (store.properties.PostCode) {
	            details.innerHTML += `<br/ >${store.properties.PostCode}`;
	          }
	          if (store.properties.City) {
	            details.innerHTML += `<br/ >${store.properties.City}`;
	          }
	          if (store.properties.HasaDisplay) {
	            details.innerHTML += `<div class="mt-2 mb-2 font-semibold flex items-center"><img src="${mapstockists.logo}" class="mr-2 w-4" /> Has a Scudo display</div>`;
	          }
	          if (store.properties.distance) {
	            const roundedDistance =
	              Math.round(store.properties.distance * 100) / 100;
	            	details.innerHTML += `<div class="font-semibold italic"><strong>${roundedDistance} miles away</strong></div>`;
	          }



	        }
	    }


      	function flyToStore(currentFeature) {
	        map.flyTo({
	          	center: currentFeature.geometry.coordinates,
                zoom: 16,
                speed: 0.85,
                pitch: 55,
                bearing: -15,
	        });
      	}

      	function createPopUp(currentFeature) {
	        const popUps = document.getElementsByClassName('mapboxgl-popup');
	        if (popUps[0]) popUps[0].remove();
	        if(currentFeature.properties.Name){

	        	let innercontent = ``
		        if (currentFeature.properties.AddressLine1) {
		          innercontent += `${currentFeature.properties.AddressLine1}`;
		        }
		        if (currentFeature.properties.AddressLine2) {
		          innercontent += `<br/ >${currentFeature.properties.AddressLine2}`;
		        }
		        if (currentFeature.properties.AddressLine3) {
		          innercontent += `<br/ >${currentFeature.properties.AddressLine3}`;
		        }
		        if (currentFeature.properties.AddressLine4) {
		          innercontent += `<br/ >${currentFeature.properties.AddressLine4}`;
		        }
		        if (currentFeature.properties.PostCode) {
		          innercontent += `<br/ >${currentFeature.properties.PostCode}`;
		        }
		        if (currentFeature.properties.City) {
		          innercontent += `<br/ >${currentFeature.properties.City}`;
		        }

	          	if (currentFeature.properties.HasaDisplay) {
	            	innercontent += `<div class="mt-2 mb-2 font-semibold flex items-center"><img src="${mapstockists.logo}" class="mr-2 w-4" /> Has a Scudo display</div>`;
	          	}
	          	if (currentFeature.properties.distance) {
		            const roundedDistance =
		              Math.round(currentFeature.properties.distance * 100) / 100;
		            	innercontent += `<div class="font-semibold italic"><strong>${roundedDistance} miles away</strong></div>`;
	          	}


		        const popup = new mapboxgl.Popup({ closeOnClick: false })
		          .setLngLat(currentFeature.geometry.coordinates)
		          .setHTML(
		            `
		            <h3>${currentFeature.properties.Name}</h3>
		            <h4>
		            	${innercontent}
		            </h4>
		            `
		          )
		          .addTo(map);


	        }

      	}


	},
	init(){	
		this.mapboxLoad()
	},
};


import LazyLoad from 'vanilla-lazyload'
import gsap from 'gsap'
import debounce from 'lodash/debounce'
import AOS from 'aos'

export const lazySelector = new LazyLoad({
    elements_selector: '.data-load-img',
})

export default {
    resizeMaxMobile(header, mobilemenu) {
        mobilemenu.style.maxHeight = window.innerHeight - header.offsetHeight + 'px';
    },
    getBreakpoint(){
        return window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
    },
    cartFunctions() {
        let cart = [];
        if(!localStorage.getItem('cart')){
            localStorage.setItem('cart', JSON.stringify(cart));
        }
    },
    // megaMenu(){
    //     const app = this

    //     const overlay = document.querySelector('.page-overlay-header')
    //     // const triggerProducts = document.querySelector('[data-products-mega]')
    //     const header = document.querySelector('.header-app__inner')
    //     // const alllinks = header.querySelectorAll('a:not([data-products-mega])')
    //     // const mega = document.querySelector('.products-megamenu')
    //     // const alllinksmega = mega.querySelectorAll('a')
    //     var tl = gsap.timeline({
    //         paused:true,
    //     });
        
        // tl
        //     .set('.products-megamenu', {
        //         display: 'block',
        //     })
        //     .to('.header-app-overlay', 0.4, {
        //         height: 'auto',
        //         ease: 'power1.out'
        //     })
        //     .to('.page-overlay-header', 0.4, {
        //         display: 'block',
        //         opacity: 1,
        //         ease: 'power1.out'
        //     },0)
        //     .to('.products-megamenu__inner', 0.3, {
        //         opacity: 1,
        //         ease: 'power1.out'
        //     },0.15)


        // overlay.addEventListener('mouseenter', function(e){
        //     e.preventDefault()
        //     if ( triggerProducts.classList.contains('opened') ) {
        //         tl.reverse();
        //         triggerProducts.classList.remove('opened')
        //     }
        // })

        // alllinks.forEach(item=>{
        //     item.addEventListener('mouseenter', function() {
        //         if ( triggerProducts.classList.contains('opened') && app.getBreakpoint() === 'desktop') {
        //             tl.reverse();
        //             triggerProducts.classList.remove('opened')

        //         }
        //     })
        // })

        // alllinksmega.forEach(item=>{
        //     item.addEventListener('click', function() {
        //         if ( triggerProducts.classList.contains('opened') && app.getBreakpoint() === 'desktop') {
        //             tl.reverse();
        //             triggerProducts.classList.remove('opened')
        //         }
        //     })
        // })
        // triggerProducts.addEventListener('click', function() {
        //     if ( triggerProducts.classList.contains('opened') && app.getBreakpoint() === 'desktop') {
        //         tl.reverse();
        //         triggerProducts.classList.remove('opened')
        //     }
        // })
        // triggerProducts.addEventListener('mouseenter', function(){
        //     if ( !this.classList.contains('opened') && app.getBreakpoint() === 'desktop') {
        //         tl.play();
        //         triggerProducts.classList.add('opened')

        //     }

        // })
    // },
    favouritesButton() {

        const isDescendant = function (parent, child) {
            let node = child.parentNode;
            while (node) {
                if (node === parent) {
                    return true;
                }

                // Traverse up to the parent
                node = node.parentNode;
            }

            // Go up until the root but couldn't find the `parent`
            return false;
        };

        const app = this
        const favouritesbutton = document.querySelector('.favourites-trigger')
        const favouritescart = document.querySelector('.cart-favourites')

        document.body.addEventListener('click', function (event) {
            if (!favouritesbutton.contains(event.target) && favouritescart.classList.contains('shown')) {
                if(!favouritescart.contains(event.target)){
                    favouritescart.classList.remove('shown')
                }
            }
        });


        favouritesbutton.addEventListener('click', function(e){
            e.preventDefault()
            // if(app.getBreakpoint() === 'desktop'){
                favouritescart.classList.toggle('shown')
            // }

        })
    },
    favouritesRemove() {
        const app = this
        const btns = document.querySelectorAll('.remove-favourite')
        const favouritescart = document.querySelector('.cart-favourites')
        const favouritescount = document.querySelector('.quantity-favourites')
        const favouritestrigger = document.querySelector('.favourites-trigger')
        btns.forEach(btn => {
            btn.addEventListener('click', function(e){
                e.preventDefault()
                const request = new XMLHttpRequest();
                const samebuttonfav = document.querySelector('.btn-favourites[data-id="'+btn.dataset.sku+'"]')

                request.open('POST', globalapp.ajax, true);
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
                request.onload = function () {
                    if (this.status >= 200 && this.status < 400) {
                        const response = JSON.parse(this.response)
                        if(response.success){
                            if(response.count === 0){
                                favouritestrigger.classList.add('not-shown')
                            } else {
                                favouritestrigger.classList.remove('not-shown')
                            }
                            // if(samebuttonfav){
                            //     samebuttonfav.classList.remove('hidden')
                            // }
                            favouritescart.innerHTML = response.data
                            favouritescount.innerHTML = response.count
                            favouritescart.classList.add('shown')
                            // btn.classList.add('hidden')
                            app.favouritesRemove()

                        }
                    } else {
                        // Response error
                    }
                };
                request.onerror = function() {
                    // Connection error
                };
                request.send('action=removeFromCart&sku=' + btn.dataset.sku);

            })
        })

    },
    searchMenu(){
        const app = this
        const overlay = document.querySelector('.page-overlay-header')
        const container = document.querySelector('.header-app-overlay')
        const triggerSearch = document.querySelector('.search-trigger')
        var tl = gsap.timeline({
            paused:true,
        });
        
        tl
            .set('.search-form-content', {
                display: 'block',
            })
            .to('.header-app-overlay', 0.6, {
                height: 'auto',
                ease: 'power2.out'
            })
            .to('.page-overlay-header', 0.6, {
                display: 'block',
                opacity: 1,
                ease: 'power4.out'
            },0)
            .to('.text-input-search', 0.4, {
                opacity: 1,
                ease: 'power2.out'
            },0.2)

        overlay.addEventListener('click', function(e){
            e.preventDefault()
            if ( triggerSearch.classList.contains('opened')) {
                tl.reverse();
                triggerSearch.classList.remove('opened')
                overlay.classList.remove('active')
            }
        })

        triggerSearch.addEventListener('click', function(e){
            e.preventDefault()
            // if(app.getBreakpoint() === 'desktop'){
                if ( this.classList.contains('opened') ) {
                    tl.reverse();
                }
                else {
                    tl.play();
                    const elem = container.querySelector('#s')
                    setTimeout(() => {
                            elem.focus()
                    },600)
                }
                triggerSearch.classList.toggle('opened')
                overlay.classList.toggle('active')
            // }
        })
    },
    mobileMenu(){
        const app = this
        const mobilemenu = document.querySelector('.mobile-menu')
        const header = document.querySelector('.header-app')
        const hamburger = document.querySelector('.hamburger-menu')

        app.resizeMaxMobile(header, mobilemenu)
        window.addEventListener('resize', debounce(() => {
          app.resizeMaxMobile(header, mobilemenu)
        }, 100), false)

        var tl = gsap.timeline({
            paused:true,
        });

        tl
            .to('#bar-top', 0.1, {
                y: 5,
                })
            .to('#bar-bottom', 0.1, {
                y: -5,
                },0)
            .to('#bar-top', 0.15, {
                rotation:45,
                y:0,
                x:10,
                transformOrigin:"left top",
                },0.075)
            .to('#bar-bottom', 0.15, {
                rotation:-45,
                y:0,
                x:10,
                transformOrigin:"left bottom",
                },0.075)
            .to('#bar-mid-l', 0.2, {
                opacity:0,
                scaleX:0,
                transformOrigin:"left",
        }, 0)
            .to('#bar-mid-r', 0.2, {
                opacity:0,
                scaleX:0,
                transformOrigin:"right",
        }, 0)
            .to('#menu-icon', 0.4, {
                rotation:90,
                }, 0.05)

            .to(['#bar-top', '#bar-bottom'], 0.25, {
                transformOrigin: "50% 50%",
                ease: 'power4.out'
                }, 0.25);

        // document.querySelector('.brand.mobile-opened, .mobile-menu-link').addEventListener('click', function(){
        //     tl.reverse();
        //     // gsap.to('.page-overlay-header', 0.3, { opacity: 0, visibility: 'hidden' })
        //     mobilemenu.classList.remove('opened')
        //     hamburger.classList.remove('opened')
        // });



        // $(document.body).on('click', '.page-overlay-header', function(e){
        //     tl.reverse();
        //     gsap.to('.page-overlay-header', 0.3, { opacity: 0, visibility: 'hidden' })
        //     $('.mobile-menu').removeClass('opened')
        //     $('.hamburger-menu').removeClass('opened')
        // })

        hamburger.addEventListener('click',function(e){
            e.preventDefault()
            if ( this.classList.contains('opened') ) {
                tl.reverse();
            }
            else {
                tl.play();
            }
            mobilemenu.classList.toggle('opened')
            this.classList.toggle('opened')

        })
        const allmenulinks = document.querySelectorAll('.mobile-menu-link')

        allmenulinks.forEach(item => {
            item.addEventListener('click', () => {
                if ( hamburger && hamburger.classList.contains('opened') ) {
                    tl.reverse();
                    mobilemenu.classList.remove('opened')
                    hamburger.classList.remove('opened')
                }
            })
        })

    },
    animationsCommon() {
        AOS.init({
            anchorPlacement: 'center-bottom',
            duration: 700,
            easing: 'outexpo',
            delay: 100,
            once: true,
        })
    },
    init() {
        this.mobileMenu()
        this.searchMenu()
        // this.megaMenu()
        this.animationsCommon()
        this.cartFunctions()
        this.favouritesButton()
        this.favouritesRemove()
    }
}


export default {
    getCount(elementss){
        let count = 0
        elementss.forEach(item => {
            if(item.checked){
                count += 1
            }
        })
        return count
    },
    swatchesLoad() {
        const app = this
        const getAllInputs = document.querySelectorAll('.input-toggles')
        const uncheckElesments = document.querySelectorAll('.element-inner-checkbox')
        if(uncheckElesments){
            uncheckElesments.forEach(item => {
                item.addEventListener('change', function(){
                    const count = app.getCount(uncheckElesments)
                    if(count == 2){
                        uncheckElesments.forEach(child => {
                            if (!child.checked) {
                                child.setAttribute('disabled', true)
                            }

                        })
                    }
                    else if( count < 2){
                        uncheckElesments.forEach(child => {
                            child.removeAttribute('disabled')
                        })
                    }
                })
            })
        }
        if(getAllInputs){
            getAllInputs.forEach(item => {
                item.addEventListener('change', function(){
                    const elementToggle = document.querySelector('[data-toggle-colours="'+item.dataset.toggle+'"]')
                    if (item.checked) {
                        elementToggle.setAttribute ('style', 'display: block !important;');
                    }
                    else {
                        elementToggle.removeAttribute ('style');
                        const uncheckEles = document.querySelectorAll('.element-inner-'+item.dataset.toggle)
                        if(uncheckEles){
                            uncheckEles.forEach(item => {
                                item.checked = false;
                            })
                        }
                    }
                })
            })
        }
    },
    init() {
        this.swatchesLoad()
    }
}
